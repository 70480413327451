export function digits(n) {
  return n < 10 ? `0${n}` : `${n}`;
}

export const debounce = function (func, wait) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export function getSafeData(object, key) {
  // безопасное получение значения объекта по ключу

  if (!object || !key) {
    return undefined;
  }

  const getValue = (object, key) => {
    let res;

    Object.keys(object).some((k) => {
      if (k === key) {
        res = object[k];
        return true;
      }

      if (object[k] && typeof object[k] === 'object') {
        res = getValue(object[k], key);

        if (res) return true;
      }

      return false;
    });

    return res;
  };

  return getValue(object, key);
}

export function getYoutubeCode(src) {
  const url = new URL(src);
  return url.searchParams.get('v');
}

export function getYoutubePreview(url) {
  const code = getYoutubeCode(url);
  return `https://img.youtube.com/vi/${code}/hqdefault.jpg`;
}
